import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getInsurances } from '@redux/actions/accounts';
import useRehydrated from '@utils/useRehydrated';

const useGetInsurances = () => {
  const loading = useSelector((state) => state.accounts.loading);
  const insurances = useSelector((state) => state.accounts.insurances, shallowEqual);

  const [isFetchingInsurances, setIsFetchingInsurances] = useState(true);

  const rehydrated = useRehydrated();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!rehydrated) return;

    dispatch(getInsurances());
    setIsFetchingInsurances(false);
  }, [rehydrated, dispatch]);

  const isLoading = isFetchingInsurances || loading;

  return { loading: isLoading, error: !isLoading && !insurances, insurances };
};

export default useGetInsurances;
