import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AccountCard from '@components/containers/AccountCard';

import { IsActiveBadge } from '@components/elements/Badge';

import shieldIcon from '@assets/img/icons/shield.svg';

import getUrl from '@utils/getUrl';

import urls from '@constants/urls';

import useGetInsurance from '@utils/useGetInsurance';

import validators from '@utils/validators';

const { isDefined } = validators.generics;

const InsuranceCard = ({ insuranceId }) => {
  const [isActive, setIsActive] = useState(undefined);

  const { insurance, loading } = useGetInsurance(insuranceId);

  const activeAccountStatus = useSelector(
    ({ settings }) => settings.constants.accountStatuses.ACTIVE,
  );

  useEffect(() => {
    if (!isDefined(activeAccountStatus) || !isDefined(insurance)) return;
    setIsActive(insurance.status === activeAccountStatus);
  }, [activeAccountStatus, insurance]);

  return (
    <AccountCard
      title="Låneskydd"
      loading={loading}
      mainAmountTitle="Försäkrat belopp"
      mainAmountValue={insurance.terms.amount}
      mainAmountIcon={shieldIcon}
      monthlyPaymentTitle="Försäkringspremie"
      monthlyPaymentValue={insurance.monthlyPayment}
      badge={<IsActiveBadge isActive={isActive} />}
      viewMoreUrl={getUrl(urls.HOME.MY_INSURANCES.DETAILS, { insuranceId })}
      passedInvoicesPayed={insurance.passedInvoicesPayed}
    />
  );
};

InsuranceCard.propTypes = {
  insuranceId: PropTypes.string.isRequired,
};

export default InsuranceCard;
