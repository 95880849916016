import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useGetInsurances from '@utils/useGetInsurances';
import { navigate } from 'gatsby';

import getUrl from '@utils/getUrl';
import urls from '@constants/urls';

const useGetInsurance = (insuranceId, fallbackUrl) => {
  const dispatch = useDispatch();
  const { loading, insurances } = useGetInsurances();

  const insurance = insurances && insurances[insuranceId];
  const error = !insurance && !loading;
  useEffect(() => {
    if (error) {
      navigate(fallbackUrl || getUrl(urls.HOME));
    }
  }, [dispatch, error, fallbackUrl]);

  return { insurance, loading };
};

export default useGetInsurance;
