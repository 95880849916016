import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoggedPage from '@components/containers/LoggedPage';
import InsuranceCard from '@components/modules/InsuranceCard';
import LoanCard from '@components/modules/LoanCard';
import Loader from '@components/elements/Loader';
import FlexBox from '@components/containers/FlexBox';
import Button from '@components/elements/Button/index';
import Headline from '@components/elements/Headline';

import useGetLoans from '@utils/useGetLoans';
import useGetInsurances from '@utils/useGetInsurances';

import { getAnnualTaxReport } from '@redux/actions/documents';
import Card from '@components/containers/Card';
import { fetchUser } from '@redux/actions/user';

const Home = () => {
  const { loans, loading: loadingLoan } = useGetLoans();
  const { insurances, loading: loadingInsurance } = useGetInsurances();

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.id);
  useEffect(() => {
    dispatch(fetchUser());
  });
  const onClick = useCallback(() => {
    dispatch(getAnnualTaxReport(userId));
  }, [dispatch, userId]);
  return (
    <LoggedPage title="Översikt">
      {loadingLoan ? <Loader /> : (
        Object.values(loans).map((loan) => (
          <LoanCard loanId={loan.id} key={loan.publicId} />
        ))
      )}
      {loadingInsurance ? <Loader /> : (
        Object.values(insurances).map((insurance) => (
          <InsuranceCard insuranceId={insurance.id} key={insurance.publicId} />
        ))
      )}
      <Card>
        <Headline fontWeight="bold" fontSize="tiny" as="h2" marginBottom="none">Årsbesked</Headline>
        <FlexBox
          flexDirection="row"
          justifyContent="center"
          marginTop="regular"
        >
          <Button size="small" color="primary" onClick={onClick}>LADDA NER</Button>
        </FlexBox>
      </Card>
    </LoggedPage>
  );
};

export default Home;
